@media print {
  /* Define your print styles here */
  body {
    font-size: 12pt;
  }

  /* Adjust styles for the table or any other printable content */
  #printableContent {
    /* Your print-specific styles here */
  }
}