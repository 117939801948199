
.carousel-image {
  max-height: 100vh;
  width: auto;
  object-fit: contain;
}

.carousel-container {
  max-height: 100vh;
  overflow: hidden;
}
