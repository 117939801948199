.image-gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 10px;
}


.property-image {
  width: 100%; /* Set the width to 100% to maintain uniform width */
  height: 100px; /* Set the desired height for all images */
  object-fit: cover; /* Preserve the aspect ratio while covering the entire container */
  cursor: pointer;
  transition: opacity 0.2s;
}


.property-image:hover {
    opacity: 0.8;
}

.image-container {
    margin-bottom: 20px;
    margin-left: 2%;
    margin-right: 2%;
}

.modal-image {
    display: block;
    margin: 0 auto; /* Center the image horizontally */
    max-width: 100%; /* Restrict the image width */
}