/* lightbox.css */
.lightbox-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.lightbox-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
}

.lightbox-image {
  max-width: 100%;
  max-height: 100%;
}

.lightbox-close {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 24px;
  color: #fff;
  cursor: pointer;
  z-index: 10;
}
